<app-page-header mainNavigation="custom" defaultBack="statistics" [popOnBack]="true">
    <app-icon left icon="arrow_back" (click)="back(false)"></app-icon>
    <div title>{{ title | translate }}</div>
</app-page-header>

<ion-content class="ion-text-black bg-white-pattern">
    @if (game) {
        <div class="bg-white flex flex-col text-center text-black py-6">
            <div class="text-lg font-bold">{{ game.name }}</div>
            <div class="text-sm text-neutral-700">
                {{ game.started_at | date: 'dd-MMM-yyyy H:mm' : '' : translateService.currentLang }}
            </div>
            <div class="flex justify-center gap-2 mt-3">
                <div class="rounded-full text-white bg-black uppercase px-2 font-bold text-xxs">
                    {{ game.sequence }}
                </div>
            </div>
        </div>

        <div class="relative" [appWebContainer]>
            <div class="flex sticky top-0 px-12 py-3 bg-white rounded-md">
                <div [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'"></div>
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                    class="flex text-center justify-center">
                    @for (user of game.users; track user) {
                        <div 
                            [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length"
                            (click)="user.user ? showPlayerInfo(user.user) : null">
                            @if (user.user) {
                                <app-profile-photo 
                                    [ultimate]="user.user.is_ultimate" 
                                    [badge]="user.user.profile?.badge" 
                                    [size]="32"
                                    [url]="user.user.profile?.profile_photo_url" 
                                    style="height: 32px"></app-profile-photo>
                            } @else {
                                <app-profile-photo [size]="32" style="height: 32px"></app-profile-photo>
                            }
                            <div class="mt-1 text-center truncate capitalize">{{ getUserName(user) }}</div>
                        </div>
                    }
                </div>
            </div>
        </div>

        <div class="mt-3 mx-4">
            <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'" 
                    [translate]="'TOTAL_SCORE'"
                    i18n="@@TOTAL_SCORE">
                    Total score
                </div>
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                    class="flex text-center justify-center">
                    @for (user of game.users; track user) {
                        <div 
                            class="text-center" 
                            [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length">
                            {{ user.total_score| number: '1.0-0' }}
                        </div>
                    }
                </div>
            </div>
            <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'" 
                    [translate]="'SUCCESS_COUNT'"
                    i18n="@@SUCCESS_COUNT">
                    Success count
                </div>
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                    class="flex text-cente justify-center">
                    @for (user of game.users; track user) {
                        <div 
                            class="text-center" 
                            [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length">
                            {{ user.success_count }}
                        </div>
                    }
                </div>
            </div>
            <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'" 
                    [translate]="'FAILURE_COUNT'"
                    i18n="@@FAILURE_COUNT">
                    Failure count
                </div>
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                    class="flex text-cente justify-center">
                    @for (user of game.users; track user) {
                        <div 
                            class="text-center" 
                            [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length">
                            {{ user.failure_count }}
                        </div>
                    }
                </div>
            </div>
            <div class="flex px-8 py-3 border-b-1 border-b-white text-sm">
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'" 
                    [translate]="'SUCCESS_RATE'"
                    i18n="@@SUCCESS_RATE">
                    Success rate
                </div>
                <div 
                    [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                    class="flex text-cente justify-center">
                    @for (user of game.users; track user) {
                        <div 
                            class="text-center" 
                            [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length">
                            {{ user.success_rate }}%
                        </div>
                    }
                </div>
            </div>
        </div>

        @for (turnByTarget of turnsByTarget; track turnByTarget){
            <app-accordion 
                class="block mt-3 mx-4 mb-2" 
                [isExpanded]="turnByTarget.expanded"
                (expandChange)="turnByTarget.expanded = !turnByTarget.expanded">
                <div accordion-header class="items-center justify-between w-full">
                    <div class="flex items-center justify-center font-bold text-sm whitespace-nowrap">
                        <div class="flex" [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'">
                            <div 
                                class="flex items-center justify-center text-black uppercase py-1 leading-none font-bold text-xxs">
                                {{ turnByTarget.target_to_throw }}
                            </div>
                        </div>
                        <div [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                            class="flex text-center justify-center mr-[-12px] pl-2">
                            @for(turn of turnByTarget.turns; track turn)
                            {
                                <div class="flex text-center justify-center" [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length">
                                    @if(turn.is_failure)
                                    {
                                        <app-icon icon="close" size="medium" class="text-red text-center"></app-icon>
                                    } 
                                    @else {
                                        <app-icon icon="check_mark" size="medium" class="text-green text-center"></app-icon>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div accordion-body class="p-2 bg-gray-50 px-8">
                    <div class="flex border-b-white border-b-1 py-2 text-sm">
                        <div [translate]="'THROWN_POINTS'" i18n="@@THROWN_POINTS" [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'">Thrown points</div>

                        <div [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                            class="flex text-center justify-center">
                            @for(turn of turnByTarget.turns; track turn)
                            {
                                <div class="text-center" [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length">{{ turn.total_score }}</div>
                            }
                        </div>
                    </div>
                    <div class="flex border-b-white border-b-1 py-2 text-sm">
                        <div [translate]="'TOTAL_SCORE_LEFT'" i18n="@@TOTAL_SCORE_LEFT" [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/4'">Total score left</div>

                        <div [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-3/4'" 
                            class="flex text-center justify-center">
                            @for(turn of turnByTarget.turns; track turn)
                            {
                                <div class="text-center" [ngClass]="game.users?.length <= 2 ? 'w-1/2' : 'w-1/' + game.users.length">{{ turn.totalScoreLeft }}</div>
                            }
                        </div>
                    </div>
                </div>
            </app-accordion>
        }
    }
</ion-content>