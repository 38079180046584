import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GameApiService } from '@dc-api/game.api.service';
import { HalfIt, User } from '@dc-core/dc-backend/dc-classes';
import { ReportOrigin } from '@dc-core/dc-backend/dc-enums';
import { DartCounterHalfItTurn } from '@dc-core/dc-gamelogic/in-game/half-it/half-it.in-game.classes';
import { ModalController, NavController } from '@ionic/angular/standalone';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth.service';
import { RouterEventsService } from '@services/router-events.service';
import moment from 'moment';
import { take } from 'rxjs';
import { ProfileDialogComponent } from 'src/app/modules/profile/dialogs/profile-dialog/profile.dialog';
import { LoadingService } from 'src/app/services/loading.service';
import { ReportUserDialogComponent } from 'src/dialogs/report-user/report-user.dialog';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-half-it-details',
    templateUrl: 'half-it-details.component.html',
})
export class HalfItDetailsComponent {
    private modalController: ModalController = inject(ModalController);

    public title: string;
    public game: HalfIt;
    public turnsByTarget: { target_to_throw: string; turns: DartCounterHalfItTurn[]; expanded: boolean }[] = [];

    constructor(
        public auth: AuthService,
        public nav: NavController,
        public translateService: TranslateService,
        private loading: LoadingService,
        public route: ActivatedRoute,
        private gameApiService: GameApiService,
        private _previousRouteService: RouterEventsService
    ) {
        this.route.queryParams.pipe(take(1)).subscribe((params) => {
            this.gameApiService
                .getHalfItById({ halfItId: params.gameId })
                .then((res) => {
                    this.game = res.data;
                    this.game.started_at = moment(this.game.started_at).toISOString();
                    this.turnsByTarget = this.groupGameTurns(this.game);

                    setTimeout(() => {
                        this.loading.dismissLoader();
                    }, 250);
                })
                .catch(() => {
                    setTimeout(() => {
                        this.loading.dismissLoader();
                    }, 250);
                    // Error go back
                    this.nav.pop();
                });
        });
    }

    private groupGameTurns(game: HalfIt): { target_to_throw: string; turns: any[]; expanded: boolean }[] {
        const groupTurnsByTarget = new Map<string, any[]>();
        const groupTurnsByUser = new Map<number, DartCounterHalfItTurn[]>();

        game.turns.forEach((turn) => {
            if (!groupTurnsByTarget.has(turn.target_to_throw)) {
                groupTurnsByTarget.set(turn.target_to_throw, []);
            }
            groupTurnsByTarget.get(turn.target_to_throw)!.push(turn);

            if (!groupTurnsByUser.has(turn.half_it_user_id)) {
                groupTurnsByUser.set(turn.half_it_user_id, []);
            }

            const completeTurn: DartCounterHalfItTurn = {
                ...turn,
                colors: [],
                isCorrect: false,
                totalScoreLeft: 0,
            };

            groupTurnsByUser.get(turn.half_it_user_id)?.push(completeTurn);
        });

        groupTurnsByUser.forEach((turns) => {
            let totalScore = 0;
            turns.forEach((turn) => {
                totalScore = turn.is_failure ? totalScore / 2 : totalScore + turn.total_score;
                turn.totalScoreLeft = Math.ceil(totalScore);
            });
        });

        groupTurnsByTarget.forEach((turns, target) => {
            const updatedTurns = turns.map((turn) => {
                const userTurns = groupTurnsByUser.get(turn.half_it_user_id) || [];
                const updatedTurn = userTurns.find((ut) => ut.id === turn.id);
                return updatedTurn ? updatedTurn : turn;
            });

            groupTurnsByTarget.set(target, updatedTurns);
        });

        return Array.from(groupTurnsByTarget.entries()).map(([target, turns]) => ({
            target_to_throw: target,
            turns,
            expanded: false,
        }));
    }

    back(refresh: boolean): void {
        if (this._previousRouteService.getPreviousUrl()?.includes('statistics/half-it/list')) {
            this.nav.navigateBack('statistics/half-it/list', {
                queryParams: {
                    refresh,
                },
            });
        } else {
            this.nav.back();
        }
    }

    getUserName(user: any): string {
        if (user.user) {
            return user.user.first_name;
        } else {
            return user.ghost_name;
        }
    }

    public showPlayerInfo(user: User) {
        this.modalController
            .create({
                component: ProfileDialogComponent,
                componentProps: {
                    user,
                    canReport: this.auth.user.id != user.id,
                },
                cssClass: environment.isWeb ? ['slide-modal', 'web'] : ['slide-modal', 'from-bottom'],
                backdropDismiss: true,
                showBackdrop: true,
            })
            .then((elem) => {
                elem.present();
                elem.onDidDismiss().then((dialogRes) => {
                    if (dialogRes.data === 'report') {
                        this.modalController
                            .create({
                                component: ReportUserDialogComponent,
                                cssClass: 'auto-height',
                                componentProps: {
                                    user,
                                    origin: 'game_details' as ReportOrigin,
                                },
                            })
                            .then((elem) => {
                                elem.present();
                            });
                    }
                });
            });
    }
}
